import React, { useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ButtonGroup,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "../lib/theme";
import { getMultipleUsersData, db } from "../../shared/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const AlbumSettings = ({
  privacyLevel,
  onPrivacyChange,
  user,
  userId,
  albumId,
  initialSharedWith = [],
  title,
  imageUrl,
  creatorName,
  invitedTo = [],
}) => {
  const [sharedEmails, setSharedEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);
  const [sharedWith, setSharedWith] = useState(initialSharedWith || []);
  const [inviteEmail, setInviteEmail] = useState("");
  const [removedUserIds, setRemovedUserIds] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [pendingInvites, setPendingInvites] = useState(invitedTo);

  useEffect(() => {
    console.log(
      "AlbumSettings mounted. albumId:",
      albumId,
      "initialSharedWith:",
      initialSharedWith
    );
    const fetchSharedEmails = async () => {
      if (!initialSharedWith || initialSharedWith.length === 0) {
        console.log("No shared users to fetch");
        return;
      }

      try {
        console.log("Fetching user data for:", initialSharedWith);
        const fetchedUsersData = await getMultipleUsersData(initialSharedWith);
        console.log("Fetched users data:", fetchedUsersData);

        if (fetchedUsersData.length === 0) {
          console.error("No user data returned from getMultipleUsersData");
          setErrorMessage(
            "Failed to fetch shared user data. Please try again later."
          );
          return;
        }

        setUsersData(fetchedUsersData);
        const emails = fetchedUsersData
          .map((userData) => userData?.email)
          .filter(Boolean);
        console.log("Extracted emails:", emails);
        setSharedEmails(emails);
        setEmailOptions(emails);
        setSharedWith(initialSharedWith);
      } catch (error) {
        console.error("Error fetching shared emails:", error);
        setErrorMessage(
          "An error occurred while fetching shared user data. Please try again later."
        );
      }
    };

    fetchSharedEmails();
  }, [initialSharedWith, albumId]);

  useEffect(() => {
    setPendingInvites(invitedTo);
  }, [invitedTo]);

  const handlePrivacyChange = (newLevel) => {
    if (user && user.uid === userId) {
      onPrivacyChange(newLevel);
    }
  };

  const handleAddEmail = async () => {
    if (!isValidEmail(newEmail)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const userDoc = await getUserDocByEmail(newEmail);
      if (userDoc) {
        const updatedEmails = [...sharedEmails, newEmail];
        setSharedEmails(updatedEmails);
        const updatedSharedWith = [...sharedWith, userDoc.id];
        setSharedWith(updatedSharedWith);
        onShareChange(updatedSharedWith);
        setNewEmail("");
        setErrorMessage("");
      } else {
        setErrorMessage(
          `There is no Golden Record account associated with ${newEmail}`
        );
        setInviteEmail(newEmail);
      }
    } catch (error) {
      console.error("Error checking user existence:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleRemoveEmail = (email) => {
    const updatedEmails = sharedEmails.filter((e) => e !== email);
    setSharedEmails(updatedEmails);
    // Find the user ID corresponding to the email and remove it from sharedWith
    const updatedSharedWith = sharedWith.filter((id) => {
      const userData = usersData.find((user) => user.id === id);
      return userData.email !== email;
    });
    onShareChange(updatedSharedWith);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    // If the input is a valid email and not already in options, add it
    if (isValidEmail(newInputValue) && !emailOptions.includes(newInputValue)) {
      setEmailOptions([...emailOptions, newInputValue]);
    }
  };

  const handleEmailChange = async (event, newEmail) => {
    if (
      newEmail &&
      isValidEmail(newEmail) &&
      !sharedEmails.includes(newEmail)
    ) {
      try {
        const userDoc = await getUserDocByEmail(newEmail);
        if (userDoc) {
          const updatedEmails = [...sharedEmails, newEmail];
          setSharedEmails(updatedEmails);
          const updatedSharedWith = [...sharedWith, userDoc.id];
          setSharedWith(updatedSharedWith);
          onShareChange(updatedSharedWith);
          setEmailOptions([...emailOptions, newEmail]);
          setErrorMessage("");
        } else {
          setErrorMessage(
            `There is no Golden Record account associated with ${newEmail}`
          );
          setInviteEmail(newEmail);
        }
      } catch (error) {
        console.error("Error checking user existence:", error);
        setErrorMessage("An error occurred. Please try again.");
      }
    }
    setInputValue("");
  };

  const handleInvite = async (email) => {
    try {
      const lowercaseEmail = email.toLowerCase(); // Lowercase the email
      const response = await fetch("/albums/send_invitation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({
          recipient_email: lowercaseEmail,
          creator_name: creatorName,
          album_id: albumId,
          title,
          image_url:
            imageUrl ||
            "https://media.cleanshot.cloud/media/39638/EqTfKzYIW6XE7D5BPycNcGWaEDFljKrGspQjzlVu.jpeg?Expires=1726182226&Signature=XX-z7OeX6I-W7RFbGufsorKXcakHHWDxNT7GmAUhEfdytiV6EQb4eaXip9wSZ0rNUQ~aLfwPhHC36hjexN~EbuAGW4NPeytfj6MKucP75Lbgox42uoB8nC61sopl1Qru3981fZKJ7Z90b2MQ6sKDIkM0q-v~adrgprl9RE47TSyI~SRfin5N9m4bWJS4IP-k6CrvQIM2dXh6lfjfeuvQ71O~Prn2exb3u67LSbtY-DfPAbe6KUMhx5vGLsYLPuOKz44n9WtxLNUTY2G9MhX9pv-S-rHk~nt-axejgsxE-3bd9JwptbvCWsCSPbwtT13eXCHc6zlBz1pnlDusXwntMg__&Key-Pair-Id=K269JMAT9ZF4GZ",
        }),
      });

      if (response.ok) {
        // Update the album document in Firestore
        const albumRef = doc(db, "albums", albumId);
        await updateDoc(albumRef, {
          invitedTo: arrayUnion(lowercaseEmail), // Use the lowercase email
        });

        setErrorMessage(`Invitation sent to ${lowercaseEmail}`);
        setInviteEmail("");
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send invitation");
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      setErrorMessage(`Failed to send invitation: ${error.message}`);
    }
  };

  const getUserDocByEmail = async (email) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty ? null : querySnapshot.docs[0];
  };

  // Helper function to validate email
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const onShareChange = async (updatedSharedWith) => {
    console.log("Updating sharedWith:", updatedSharedWith);
    setSharedWith(updatedSharedWith);

    if (!albumId) {
      console.error("albumId is undefined");
      setErrorMessage("Cannot update album: missing albumId");
      return;
    }

    try {
      console.log("Firestore db object:", db);
      console.log("albumId:", albumId);
      const albumRef = doc(db, "albums", albumId);
      console.log("Album reference:", albumRef);

      await updateDoc(albumRef, { sharedWith: updatedSharedWith });
      console.log("Album updated successfully");
    } catch (error) {
      console.error("Error updating album shared users:", error);
      setErrorMessage(`Failed to update shared users: ${error.message}`);
    }
  };

  const handleRemoveUser = (userIdToRemove) => {
    const updatedSharedWith = sharedWith.filter((id) => id !== userIdToRemove);
    const updatedEmails = sharedEmails.filter((email) => {
      const userData = usersData.find((user) => user.id === userIdToRemove);
      return userData.email !== email;
    });
    setSharedEmails(updatedEmails);
    setRemovedUserIds([...removedUserIds, userIdToRemove]);
    onShareChange(updatedSharedWith);
  };

  const handleCancelInvite = async (invite) => {
    try {
      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        invitedTo: arrayRemove(invite),
      });
      setPendingInvites((prevInvites) =>
        prevInvites.filter((item) => item !== invite)
      );
    } catch (error) {
      console.error("Error cancelling invitation:", error);
      setErrorMessage(`Failed to cancel invitation: ${error.message}`);
    }
  };

  const getInviteEmail = (invite) => {
    if (typeof invite === "string") return invite;
    if (typeof invite === "object" && invite !== null) {
      return invite.email || invite.uid || JSON.stringify(invite);
    }
    return String(invite);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddEmail();
    }
  };

  return (
    <Accordion sx={{ mt: 2, bgcolor: colors.lightYellow }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Settings and Permissions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography gutterBottom>Privacy Level:</Typography>
        <ButtonGroup
          variant="contained"
          size="small"
          aria-label="privacy level button group"
        >
          {["Private", "Shared", "Public"].map((label, index) => (
            <Button
              key={label}
              onClick={() => handlePrivacyChange(index)}
              variant={privacyLevel === index ? "contained" : "secondary"}
              sx={{
                bgcolor: privacyLevel === index ? colors.navy : "transparent",
                color: privacyLevel === index ? colors.white : colors.navy,
                borderColor: colors.navy,
                "&:hover": {
                  bgcolor:
                    privacyLevel === index
                      ? colors.navy
                      : "rgba(0, 0, 0, 0.04)",
                  borderColor: colors.navy,
                },
                fontSize: "0.8rem",
                padding: "4px 8px",
              }}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>

        {privacyLevel === 1 && (
          <>
            <Typography gutterBottom sx={{ mt: 4 }}>
              Share with:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <TextField
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                onKeyPress={handleKeyPress}
                variant="outlined"
                placeholder="Enter email address"
                fullWidth
                size="small"
                sx={{ mr: 1 }}
              />
              <Button
                onClick={handleAddEmail}
                variant="contained"
                sx={{
                  bgcolor: colors.navy,
                  color: colors.white,
                  "&:hover": {
                    bgcolor: colors.navy,
                  },
                  minWidth: "80px",
                  height: "40px", // Match TextField height
                  boxSizing: "border-box",
                }}
              >
                Add
              </Button>
            </Box>

            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
                {inviteEmail && (
                  <>
                    <br />
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => handleInvite(inviteEmail)}
                      style={{
                        display: "block",
                        textAlign: "left",
                        mt: 1,
                        fontWeight: "bold",
                      }}
                    >
                      Invite them to create an account!
                    </Link>
                  </>
                )}
              </Alert>
            )}

            <Typography sx={{ mt: 4, mb: 0, fontWeight: "bold" }}>
              Shared with:
            </Typography>
            {usersData.length === 0 ? (
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                  color: "text.secondary",
                  mt: 1,
                  mb: 2,
                }}
              >
                This album hasn't been shared with anyone yet.
              </Typography>
            ) : (
              <List>
                {usersData
                  .filter((userData) => !removedUserIds.includes(userData.id))
                  .map((userData) => (
                    <ListItem key={userData.id}>
                      <ListItemText primary={userData.email} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveUser(userData.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            )}

            <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
              Pending Invites:
            </Typography>
            {pendingInvites.length === 0 ? (
              <Typography
                variant="body2"
                sx={{ fontStyle: "italic", color: "text.secondary" }}
              >
                No pending invites.
              </Typography>
            ) : (
              <List>
                {pendingInvites.map((invite, index) => {
                  const email = getInviteEmail(invite);
                  return (
                    <ListItem key={index} sx={{ m: 0, p: 0 }}>
                      <ListItemText primary={email} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="cancel"
                          onClick={() => handleCancelInvite(invite)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AlbumSettings;
